import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LazyImage from './LazyImage';

const SponsorDisclosure = ({
  className,
  path,
  target,
  sponsor,
}) => (
  <a
    className={classnames('sponsor-disclosure', className)}
    href={path}
    target={target}
    rel="nofollow noopener"
  >
    <div className="disclosure">
      <span>Sponsored By</span>
    </div>
    <LazyImage
      src={sponsor.image_src}
      title={sponsor.name}
      alt={sponsor.name}
    />
  </a>
);

SponsorDisclosure.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  target: PropTypes.string,
  sponsor: PropTypes.shape({
    name: PropTypes.string,
    image_src: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
};

SponsorDisclosure.defaultProps = {
  className: undefined,
  target: '_blank',
};

export default SponsorDisclosure;