import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PremiumDisclosure = ({
  className,
}) => (
  <span className={classnames('premium-disclosure', className)}>
    Premium
  </span>
);

PremiumDisclosure.propTypes = {
  className: PropTypes.string,
};

PremiumDisclosure.defaultProps = {
  className: undefined,
};

export default PremiumDisclosure;
