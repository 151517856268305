import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import LazyImage from './LazyImage';
import SponsorDisclosure from './SponsorDisclosure';
import PremiumDisclosure from './PremiumDisclosure';

import moment from '../lib/moment';

import styles from './RelatedPost.module.scss';

const RelatedPost = ({
  className,
  id,
  path,
  featured_media_src,
  featured_media_white,
  title,
  sponsor,
  premium,
  published_at,
}) => (
  <article
    className={classnames(styles.post, className)}
    id={`post-${id}`}
  >
    {!isEmpty(featured_media_src) ? (
      <figure>
        <div className={styles.thumbnail}>
          <a href={path}>
            <LazyImage
              className={featured_media_white ? 'darken' : undefined}
              src={featured_media_src}
              alt={title}
              title={title}
            />
          </a>
        </div>
      </figure>
    ) : null}
    <div className={styles.content}>
      <h3 className={styles.headline}>
        <a href={path}>{title}</a>
      </h3>
      {sponsor ? (
        <SponsorDisclosure
          path={path}
          sponsor={sponsor}
        />
      ) : (
        <p className={styles.byline}>
          <time className={classnames(styles.timestamp, 'timestamp')} dateTime={published_at} data-relative="true">
            {moment(published_at).fromNow()}
          </time>
          {premium ? (
            <PremiumDisclosure className={styles.premium} />
          ) : null}
        </p>
      )}
    </div>
  </article>
);

RelatedPost.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  featured_media_src: PropTypes.string,
  featured_media_white: PropTypes.bool,
  title: PropTypes.string.isRequired,
  sponsor: PropTypes.shape({
  }),
  premium: PropTypes.bool,
  published_at: PropTypes.string.isRequired,
};

RelatedPost.defaultProps = {
  className: undefined,
  featured_media_src: undefined,
  featured_media_white: false,
  premium: false,
  sponsor: undefined,
};

export default RelatedPost;
