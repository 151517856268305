import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import RelatedPost from './RelatedPost';
import SwiperPager from './SwiperPager';

import fetchApi from '../lib/fetchApi';

import styles from './RelatedPosts.module.scss';

const pagerId = 'swiper-pager-related';
const navNextId = 'swiper-nav-related-next';
const navPrevId = 'swiper-nav-related-prev';

const RelatedPosts = ({
  className,
  path,
  title,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    fetchApi(path, { cache: 'default' })
      .then((data) => {
        setLoaded(true);
        setPosts(data);
      })
      .catch((err) => {
        setLoaded(false);
      });
  }, [path]);

  return loaded ? (
    <div
      id="related-posts"
      className={classnames(styles.container, className)}
    >
      <div className={styles.title}>
        <span>{title}</span>
      </div>
      <SwiperPager
        className={styles.pager}
        pagerId={pagerId}
        navPrevId={navPrevId}
        navNextId={navNextId}
      />
      <Swiper
        modules={[Navigation, Pagination]}
        className={styles.swiper}
        slidesPerView={3}
        slidesPerGroup={3}
        spaceBetween={45}
        pagination={{
          el: `#${pagerId}`,
          clickable: true,
        }}
        navigation={{
          nextEl: `#${navNextId}`,
          prevEl: `#${navPrevId}`,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 30,
          },
          767: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 45,
          },
        }}
      >
        {(posts || []).map((post) => (
          <SwiperSlide
            key={post.id}
            className={styles.slide}
          >
            <RelatedPost {...post} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};

RelatedPosts.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
};

RelatedPosts.defaultProps = {
  className: undefined,
  title: 'What to Read Next...',
};

export default RelatedPosts;