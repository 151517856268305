import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as NextIcon } from '../icons/date-picker-next.svg';
import { ReactComponent as PrevIcon } from '../icons/date-picker-previous.svg';

import styles from './SwiperPager.module.scss';

const SwiperPager = ({
  className,
  pagerId,
  navPrevId,
  navNextId,
}) => (
  <div className={classnames(styles.pager, className)}>
    <div id={pagerId} className={styles.bullets} />
    <button id={navPrevId} className={classnames(styles.navButton, styles.prev)}><PrevIcon /></button>
    <button id={navNextId} className={classnames(styles.navButton, styles.next)}><NextIcon /></button>
  </div>
);

SwiperPager.propTypes = {
  className: PropTypes.string,
  pagerId: PropTypes.string.isRequired,
  navPrevId: PropTypes.string.isRequired,
  navNextId: PropTypes.string.isRequired,
};

SwiperPager.defaultProps = {
  className: undefined,
};

export default SwiperPager;
